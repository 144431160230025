<template>
  <div class="car" id="car">
    <div class="car__explain">
      <p>
        采购车说明：A、采购车里面的商品种类总数量不能大于50，单个商品的采购总量不能大于10万件；<br />B、采购车里面的自有仓商品和合作仓商品不能同时下单；<br />C、采购车里面的商品价格可能和下单的时候不一致，具体请按照下单时候的订单金额为准，如果商品价格信息发生变动，刷新页面获取最新价格；
      </p>
    </div>
    <div class="table">
      <ul class="table__top">
        <li class="table__top--check">
          <el-checkbox
            v-model="allchecked"
            @change="allcheckedChange"
          ></el-checkbox>
        </li>
        <li class="table__top--checkText">全选</li>
        <li class="table__top--name">商品信息</li>
        <li class="table__top--level">规格</li>
        <li class="table__top--price">批发价</li>
        <li class="table__top--quantity">采购量</li>
        <li class="table__top--allPrice">小计</li>
        <li class="table__top--operation">操作</li>
      </ul>
      <!-- 自有仓商品 -->
      <div v-if="normalShoppingCartVOList.length > 0">
        <div class="table__title">
          <el-checkbox
            v-model="normalChecked"
            @change="normalCheckedAllChange"
          ></el-checkbox>
          <span class="table__title--title">自有仓商品</span>
        </div>
        <ul class="table__content">
          <li
            v-for="(i, index) in normalShoppingCartVOList"
            :key="i.id"
            class="table__content--item"
            :class="i.status !== '1' ? 'table__content--itemInvalid' : ''"
          >
            <div class="table__content--check">
              <el-checkbox
                v-model="i.isChecked"
                class="table__content--checkBox"
                v-if="i.status === '1'"
                @change="normalCheckedChange"
              ></el-checkbox>
              <span v-else class="table__content--invalid">失效</span>
            </div>
            <div class="table__content--name">
              <oss-image :src="i.imgUrl" class="table__content--nameImg" />
              <p class="table__content--nameName">{{ i.commodityName }}</p>
              <span class="table__content--rebate" v-if="i.creditBack === '1'"
                >返利</span
              >
              <span class="table__content--rebate" v-if="i.seckillRuleCartVO"
                >限时</span
              >
            </div>
            <div class="table__content--level">
              <p>
                {{ i.firstLevel ? i.firstLevel : '' }}{{ i.secondLevel | judge
                }}{{ i.threeLevel | judge }}
              </p>
              <p>规格标识:{{ i.skuId }}</p>
            </div>
            <div class="table__content--price">
              <p
                :style="{
                  color: i.status === '1' ? '' : '#cecece',
                }"
              >
                ￥{{ i.price }}
              </p>
            </div>
            <div class="table__content--quantity">
              <el-input-number
                v-if="i.status === '1'"
                :min="i.miniOrderQuantity"
                v-model="i.quantity"
                @change="
                  (currentValue, oldValue) => {
                    quantityChange(currentValue, oldValue, i.id, 'normal');
                  }
                "
                class="table__content--quantityInput"
              ></el-input-number>
              <p
                v-if="i.status === '1'"
                class="table__content--quantityInputNum"
              >
                起订量{{ i.miniOrderQuantity }}件起
              </p>
              <span v-else class="table__content--inputInvalid">{{
                i.quantity
              }}</span>
            </div>
            <div class="table__content--allPrice">
              <p
                :style="{
                  color: i.status === '1' ? '' : '#cecece',
                }"
              >
                ￥{{ (i.price * i.quantity).toFixed(2) }}
              </p>
            </div>
            <div class="table__content--operation">
              <el-button
                @click="handleDelete(i, index, 'normal')"
                type="text"
                class="table__content--btn"
                >删除</el-button
              >
            </div>
          </li>
        </ul>
      </div>
      <!-- 合作仓商品 -->
      <div v-if="internationShoppingCartVOList.length > 0">
        <div class="table__title">
          <el-checkbox
            v-model="globalChecked"
            @change="globalCheckedAllChange"
          ></el-checkbox>
          <span class="table__title--title">合作仓商品</span>
        </div>
        <ul class="table__content">
          <li
            v-for="(i, index) in internationShoppingCartVOList"
            :key="i.id"
            class="table__content--item"
            :class="i.status !== '1' ? 'table__content--itemInvalid' : ''"
          >
            <div class="table__content--check">
              <el-checkbox
                v-model="i.isChecked"
                class="table__content--checkBox"
                v-if="i.status === '1'"
                @change="globalCheckedChange"
              ></el-checkbox>
              <span v-else class="table__content--invalid">失效</span>
            </div>
            <div class="table__content--name">
              <oss-image :src="i.imgUrl" class="table__content--nameImg" />
              <p class="table__content--nameName">{{ i.commodityName }}</p>
              <span class="table__content--rebate" v-if="i.creditBack === '1'"
                >返利</span
              >
              <span class="table__content--rebate" v-if="i.seckillRuleCartVO"
                >限时</span
              >
            </div>
            <div class="table__content--level">
              <p>
                {{ i.firstLevel ? i.firstLevel : '' }}{{ i.secondLevel | judge
                }}{{ i.threeLevel | judge }}
              </p>
              <p>规格标识:{{ i.skuId }}</p>
            </div>
            <div class="table__content--price">
              <p
                :style="{
                  color: i.status === '1' ? '' : '#cecece',
                }"
              >
                ￥{{ i.price }}
              </p>
            </div>
            <div class="table__content--quantity">
              <el-input-number
                v-if="i.status === '1'"
                :min="i.miniOrderQuantity"
                v-model="i.quantity"
                @change="
                  (currentValue, oldValue) => {
                    quantityChange(currentValue, oldValue, i.id, 'global');
                  }
                "
                class="table__content--quantityInput"
              ></el-input-number>
              <p
                v-if="i.status === '1'"
                class="table__content--quantityInputNum"
              >
                起订量{{ i.miniOrderQuantity }}件起
              </p>
              <span v-else class="table__content--inputInvalid">{{
                i.quantity
              }}</span>
            </div>
            <div class="table__content--allPrice">
              <p
                :style="{
                  color: i.status === '1' ? '' : '#cecece',
                }"
              >
                ￥{{ (i.price * i.quantity).toFixed(2) }}
              </p>
            </div>
            <div class="table__content--operation">
              <el-button
                @click="handleDelete(i, index, 'global')"
                type="text"
                class="table__content--btn"
                >删除</el-button
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div
      style="text-align: center; width: 1100px"
      v-if="
        normalShoppingCartVOList.length === 0 &&
          internationShoppingCartVOList.length === 0
      "
    >
      暂无商品
    </div>
    <!-- 合计 -->
    <div id="combined-position"></div>
    <div
      class="combined-div"
      :style="positionFlag ? styles.fixed : styles.relative"
    >
      <div class="combined">
        <div class="fl">
          <el-checkbox v-model="allchecked" @change="allcheckedChange"
            >全选</el-checkbox
          >
          <el-button @click="allDel" type="text"
            ><i class="iconfont icon-shanchu"></i>批量删除</el-button
          >
          <el-button @click="delDisableShop" type="text"
            ><i class="iconfont icon-yijianqungchu"></i>清空失效商品</el-button
          >
        </div>
        <div class="fr">
          <p>
            已选商品
            <span>{{ count }}</span
            >种 <span>{{ total }}</span
            >件，总计（不含运费）：
            <span>￥{{ allPrice }}</span>
          </p>
          <el-button @click="settlement" type="primary">去结算</el-button>
        </div>
      </div>
    </div>
    <el-dialog
      title="合并结算提示"
      :visible.sync="dialogFormVisible"
      width="470px"
      class="dialog"
      :close-on-click-modal="false"
    >
      <p class="dialog__title">以下商品不支持同时结算，请分开结算！</p>
      <p class="dialog__item">
        <el-radio v-model="dialogValue" label="1"
          >自有仓商品(共选中{{ normalCount }}种商品,{{
            normalTotal
          }}件)</el-radio
        >
      </p>
      <p class="dialog__item">
        <el-radio v-model="dialogValue" label="0"
          >合作仓商品(共选中{{ globalCount }}种商品,{{
            globalTotal
          }}件)</el-radio
        >
      </p>
      <div slot="footer" class="dialog__footer">
        <el-button
          @click="dialogFormVisible = false"
          class="dialog__footer--btn"
          >取 消</el-button
        >
        <el-button type="primary" @click="saveType">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { listMyAll, removeByIds, updateCommodityQuantity } from '@/api/car/car';
import { mapMutations, mapActions } from 'vuex';
export default {
  name: 'car',
  data() {
    return {
      normalShoppingCartVOList: [],
      internationShoppingCartVOList: [],
      normalChecked: false,
      globalChecked: false,
      allchecked: false,
      domLeft: 0,
      styles: {
        fixed: {
          position: 'fixed',
          left: this.domLeft,
        },
        relative: {
          position: 'relative',
        },
      },
      positionFlag: false,
      dialogFormVisible: false,
      dialogValue: '1',
    };
  },
  computed: {
    // 自有仓商品种类数
    normalCount() {
      return this.calculationCount(this.normalShoppingCartVOList);
    },
    // 自有仓商品件数
    normalTotal() {
      return this.calculationTotal(this.normalShoppingCartVOList);
    },
    // 合作仓商品种类数
    globalCount() {
      return this.calculationCount(this.internationShoppingCartVOList);
    },
    // 合作仓商品件数
    globalTotal() {
      return this.calculationTotal(this.internationShoppingCartVOList);
    },
    // 商品种类数
    count() {
      const goodsArr = [
        ...this.normalShoppingCartVOList,
        ...this.internationShoppingCartVOList,
      ];
      return this.calculationCount(goodsArr);
    },
    // 商品件数
    total() {
      const goodsArr = [
        ...this.normalShoppingCartVOList,
        ...this.internationShoppingCartVOList,
      ];
      return this.calculationTotal(goodsArr);
    },
    // 商品总额
    allPrice() {
      const goodsArr = [
        ...this.normalShoppingCartVOList,
        ...this.internationShoppingCartVOList,
      ];
      let price = 0;
      goodsArr.forEach((i) => {
        if (i.status === '1' && i.isChecked) {
          price += i.quantity * i.price;
        }
      });
      return price.toFixed(2);
    },
  },
  created() {
    this.getList();
    this.getCountStatisticsHot();
  },
  mounted() {
    this.domLeft = document.getElementById('car').getBoundingClientRect().left;
    window.addEventListener('scroll', this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  methods: {
    // 获取购物车列表
    async getList() {
      const { data: res } = await listMyAll();
      res.normalShoppingCartVOList &&
        res.normalShoppingCartVOList.forEach((i) => {
          if (i.status === '1') {
            i.isChecked = false;
          }
        });
      res.internationShoppingCartVOList &&
        res.internationShoppingCartVOList.forEach((i) => {
          if (i.status === '1') {
            i.isChecked = false;
          }
        });
      this.normalShoppingCartVOList = res.normalShoppingCartVOList || [];
      this.internationShoppingCartVOList =
        res.internationShoppingCartVOList || [];
      this.$nextTick(() => {
        this.handleScroll();
      });
    },
    // 水羊自有仓商品选择
    normalCheckedChange() {
      let flag = true;
      for (const i of this.normalShoppingCartVOList) {
        if (i.status === '1' && !i.isChecked) {
          flag = false;
          break;
        }
      }
      this.normalChecked = flag;
      this.allcheckedFn();
    },
    normalCheckedAllChange(val) {
      this.normalShoppingCartVOList.forEach((i) => {
        if (i.status === '1') {
          i.isChecked = val;
        }
      });
      this.allcheckedFn();
    },
    // 水羊合作仓商品选择
    globalCheckedChange() {
      let flag = true;
      for (const i of this.internationShoppingCartVOList) {
        if (i.status === '1' && !i.isChecked) {
          flag = false;
          break;
        }
      }
      this.globalChecked = flag;
      this.allcheckedFn();
    },
    globalCheckedAllChange(val) {
      this.internationShoppingCartVOList.forEach((i) => {
        if (i.status === '1') {
          i.isChecked = val;
        }
      });
      this.allcheckedFn();
    },
    // 全选
    allcheckedChange(val) {
      this.normalChecked = val;
      this.globalChecked = val;
      this.normalShoppingCartVOList.forEach((i) => {
        if (i.status === '1') {
          i.isChecked = val;
        }
      });
      this.internationShoppingCartVOList.forEach((i) => {
        if (i.status === '1') {
          i.isChecked = val;
        }
      });
    },
    allcheckedFn() {
      if (
        this.normalShoppingCartVOList.length !== 0 &&
        this.internationShoppingCartVOList.length !== 0 &&
        this.globalChecked &&
        this.normalChecked
      ) {
        this.allchecked = true;
      } else if (
        this.normalShoppingCartVOList.length !== 0 &&
        this.internationShoppingCartVOList.length === 0 &&
        this.normalChecked
      ) {
        this.allchecked = true;
      } else if (
        this.internationShoppingCartVOList.length !== 0 &&
        this.normalShoppingCartVOList.length === 0 &&
        this.globalChecked
      ) {
        this.allchecked = true;
      } else {
        this.allchecked = false;
      }
    },
    // 单个删除商品
    handleDelete(val, index, type) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          const ids = [val.id];
          removeByIds(ids).then((response) => {
            if (type === 'normal') {
              this.normalShoppingCartVOList.splice(index, 1);
              this.UPDATE_SET_CARNUMBER(-val.quantity);
              this.$message({
                type: 'success',
                message: '删除成功！',
              });
            } else {
              this.internationShoppingCartVOList.splice(index, 1);
              this.UPDATE_SET_CARNUMBER(-val.quantity);
              this.$message({
                type: 'success',
                message: '删除成功！',
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 批量删除商品
    allDel() {
      const goodsArr = [
        ...this.normalShoppingCartVOList,
        ...this.internationShoppingCartVOList,
      ];
      const ids = [];
      goodsArr.forEach((i) => {
        if (i.status === '1' && i.isChecked) {
          ids.push(i.id);
        }
      });
      if (ids.length > 0) {
        this.$confirm('确认要批量删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            removeByIds(ids).then((response) => {
              this.normalChecked = false;
              this.globalChecked = false;
              this.allchecked = false;
              this.getList();
              this.getCountStatisticsHot();
              this.$message({
                type: 'success',
                message: '删除成功！',
              });
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      } else {
        this.$message({
          type: 'warning',
          message: '请先选择需要删除商品',
        });
      }
    },
    // 修改商品数量
    quantityChange(currentValue, oldValue, id, type) {
      const data = {
        newQuantity: currentValue,
        id: id,
      };
      updateCommodityQuantity(data)
        .then((res) => {
          this.UPDATE_SET_CARNUMBER(currentValue - oldValue);
        })
        .catch(() => {
          if (type === 'normal') {
            this.normalShoppingCartVOList.forEach((item) => {
              if (item.id === id) {
                item.quantity = oldValue;
              }
            });
          } else {
            this.internationShoppingCartVOList.forEach((item) => {
              if (item.id === id) {
                item.quantity = oldValue;
              }
            });
          }
        });
    },
    // vuex 采购车商品数量
    ...mapMutations(['UPDATE_SET_CARNUMBER']),
    ...mapActions(['getCountStatisticsHot']),
    // 清空失效商品
    delDisableShop() {
      this.$confirm('确认要清空失效商品吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          const goodsArr = [
            ...this.normalShoppingCartVOList,
            ...this.internationShoppingCartVOList,
          ];
          const ids = [];
          goodsArr.forEach((i) => {
            if (i.status !== '1') {
              ids.push(i.id);
            }
          });
          if (ids.length < 1) {
            this.$message({
              type: 'warning',
              message: '无失效商品',
            });
          } else {
            removeByIds(ids).then((response) => {
              this.normalChecked = false;
              this.globalChecked = false;
              this.allchecked = false;
              this.getList();
              this.$message({
                type: 'success',
                message: '已清空',
              });
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          });
        });
    },
    // 结算
    settlement() {
      let normalNum = false;
      let globalNum = false;
      for (const i of this.normalShoppingCartVOList) {
        if (i.status === '1' && i.isChecked) {
          normalNum = true;
          break;
        }
      }
      for (const i of this.internationShoppingCartVOList) {
        if (i.status === '1' && i.isChecked) {
          globalNum = true;
          break;
        }
      }

      if (normalNum && globalNum) {
        this.dialogFormVisible = true;
      }
      if (!normalNum && !globalNum) {
        this.$message({
          type: 'warning',
          message: '请先选择结算商品',
        });
      }
      if (normalNum && !globalNum) {
        this.settlementNormal('normal');
      }
      if (!normalNum && globalNum) {
        this.settlementNormal('internation');
      }
    },
    // 弹窗结算
    saveType() {
      if (this.dialogValue === '1') {
        this.settlementNormal('normal');
      } else {
        this.settlementNormal('internation');
      }
    },
    // 商品类型
    settlementNormal(type) {
      const data = [];
      this[`${type}ShoppingCartVOList`].forEach((i) => {
        if (i.status === '1' && i.isChecked) {
          i.allPrice = (i.price * i.quantity).toFixed(2);
          data.push(i);
        }
      });
      sessionStorage.setItem('orderDetail', JSON.stringify(data));
      this.$router.push({
        path: '/syoung/checkout/list',
        query: { 
          type,
          brandType: type === 'normal' ? 'NORMAL' : 'INTERNATION'
        },
      });
    },
    // 结算按钮样式
    handleScroll() {
      const divTop = document
        .getElementById('combined-position')
        .getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      if (divTop + 70 > windowHeight) {
        this.positionFlag = true;
      } else {
        this.positionFlag = false;
      }
    },
    // 计算商品种类数
    calculationCount(list) {
      let num = 0;
      list.forEach((i) => {
        if (i.status === '1' && i.isChecked) {
          num += 1;
        }
      });
      return num;
    },
    // 计算商品总件数
    calculationTotal(list) {
      let num = 0;
      list.forEach((i) => {
        if (i.status === '1' && i.isChecked) {
          num += i.quantity;
        }
      });
      return num;
    },
  },
  filters: {
    judge(val) {
      if (val) {
        return ` / ${val}`;
      }
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import '@/views/car/styles';
</style>
